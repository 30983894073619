.sellerNavUserWrapper {
  width: 220px;
  position: sticky;
  top: 0;
  left: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 10px 0px;
  background: #fff;
}
.sellerNavUserWrapper .DhashNavLogo {
  width: 100px;
  height: auto;
  margin: 30px 0;
}
.sellerNavUserWrapper .DhashNavLink {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  align-items: start;
  color: #6504B5;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 20px 0;
}
.sellerNavUserWrapper .DhashNavLink.active {
  color: white;
  background: #6504B5;
}
.sellerNavUserWrapper .DhashNavLink.logo {
  justify-content: center;
}
.sellerNavUserWrapper .DhashNavLink .DashNavIcon {
  width: 30px;
  height: auto;
  margin-right: 20px;
}
.sellerNavUserWrapper .DhashNavLink p {
  font-family: poppins;
  font-weight: 500;
  margin: 0;
}/*# sourceMappingURL=style.css.map */