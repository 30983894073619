.MatingDropdownWrapper{
    position: relative;
}

.dropDownMating{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0px 5px 10px 0px #b6b6b6;
    background: #fff;
    max-height: 200px;
    overflow: auto;
    z-index: 10;
}

.selectedDropDown{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    // box-shadow: 0px 0px 2px 0px #b6b6b6;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    p{
        margin-bottom: 0px;
    }
    div{
        display: flex;
        position: relative;
        flex-direction: row;
        align-items: center;
        justify-content: start;
    }
}
.selectedDogImg{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    margin-right: 20px;
}

.selectDropDown{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 10px;
    border-bottom: 1px solid rgba(181, 181, 181, 0.3);
    cursor: pointer;
    &:hover{
        background: rgba(181, 181, 181, 0.3);
    }
}