.dogCardWrapper{
    width: 32%;
    display: flex;
    flex-direction: column;
    background: #fff;
    // border: 1px solid #6504b5;
    margin-bottom: 24px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px #b6b6b6;
    // box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    margin-right: 1%;
    // color: white;

    .dogCardSlider{
        width: 100%;
    }

    .DogImageWrapper{
        height: 25vh;
        width: 100%;
        background-repeat: no-repeat!important;
        background-size: cover!important;
        background-position: center!important;
    }

    .dogCardDetails{
        padding: 20px;
        width: 100%;

        .dogDetails{
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
        }

        .dogCardHeader{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            img{
                width: 22px;
                margin-left: 20px;
            }
        }

        h3{
            font-family: changa one;
            margin: 0 0 0px 0;
            font-size: 22px;
            color: #6504b5;
        }
        p{
            font-family: poppins;
            margin: 5px 0;
            font-size: 13px;
            font-weight: 500;
        }
        

        .detailWrapper{
            display: flex;
            align-items: center;
            img{
                width: 15px;
                height: auto;
                margin-right: 5px;
            }
        }

        .actionWrapper{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            button{
                padding: 10px 20px;
                text-align: center;
                // letter-spacing: 0.2px;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 12px;
                font-family: poppins;
                border: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                width: 48%;
                // box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
            }
            a{
                text-decoration: none;
                width: 48%;

                button{
                    width: 100%;
                }
            }
            .whatsappEnquire{
                // background: #64b161;
                background: rgb(41, 178, 0);
                color: white;
                box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
                margin-left: 0px;

                img{
                    height: 20px;
                    width: auto;
                    margin-right: 10px;
                }
            }
        }
        .actionWrapper2{
            margin-top: 15px;
            width: 100%;

            button{
                width: 100%;
                padding: 10px 20px;
                // letter-spacing: 0.2px;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 12px;
                font-family: poppins;
                border: 0px;
                text-align: center;
                border-radius: 8px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .dogCardWrapper{
        width: 100%;
        margin-right: 0;
    }
}