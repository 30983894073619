.seeMoreButton{
    margin-top: 10px;
    border: 0px;
    background: none;
    color: #6504b5;
    padding: 0;
}

@media only screen and (max-width: 768px) {
    .seeMoreButton{
        font-size: 0.8rem;
    }
}