.sellerprofile {
  padding: 15px 30px;
}
.sellerprofile .profileForm .formRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}
.sellerprofile .profileForm .formRow div {
  width: 32%;
  display: flex;
  flex-direction: column;
}
.sellerprofile .profileForm .formRow input, .sellerprofile .profileForm .formRow select {
  padding: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}/*# sourceMappingURL=style.css.map */