div.pageHeadingSticky.viewDog{
    justify-content: start!important;

    // .bestBuy{
    //     margin-left: 20px;
    //     background-color: #2e0152;
    //     color: #fff
    // }
}
.dogViewWrapper{
    font-family: poppins;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 15px;
    .mainInfo{
        width: 70%;
        padding: 0 20px 0 50px;
        .directInfoWrapper{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: start;
            margin-bottom: 20px;
            margin-top: 60px;

            &.scores{
                color: #fff;
                position: relative;
                align-items: stretch;
                margin-top: 100px;

                .directInfo{
                    position: relative;
                    padding: 50px 20px 20px;
                    margin-bottom: 100px;
                    background: #6504b5;
                    width: 33%;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                    border-radius: 12px;

                    img{
                        position: absolute;
                        top: -40%;
                        height: 100px;
                        width: auto;
                        left: 50%;
                        transform: translateX(-50%) scale(1.6);
                    }

                    p{
                        margin-bottom: 0;
                        letter-spacing: 1px;
                        font-size: 1rem;
                        font-family: poppins;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }
                }

            }

            .directInfo{
                width: 25%;
                margin-bottom: 15px;
                text-align: center;

                & p{
                    font-family: poppins;
                    text-transform: capitalize;
                    font-size: 1sem;
                    margin-bottom: 30px;
                }

                .dogDetailIcon{
                    font-size: 2em;
                }


                .InfoHeader{
                    font-size: 1rem;
                    font-family: durea;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    margin-top: 20px;
                }
                span{
                    font-weight: 600;
                }
            }
        }
        .descriptionWrapper{
            margin-bottom: 40px;
            h4{
                font-size: 20px;
                font-family: changa one;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                margin-bottom: 15px;
            }
        }
    }
    .galleryWrapper{
        width: 30%;
        padding: 0 0 0 20px;
        position: sticky;
        top: 140px;
        img{
            width: 100%;
            height: auto;
            border-radius: 8px;
        }
        p{
            font-family: poppins;
            margin-bottom: 30px;
        }
    }
}

.listerDetailWrapper{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    text-transform: capitalize;
    border-radius: 12px;
    padding: 20px 30px !important;
    margin-bottom: 30px;
    
    p{
        margin: 0;
        font-family: poppins;
        font-size: 1.2em;
        font-weight: 600;
    }
}

.listerDetailWrapper, .actionWrapper{
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 15px;

    div{
        display: flex;
        justify-content: start;
        align-items: center;

    }
    img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        // margin-right: 20px;
        // margin-left: 20px;
    }
    
    button{
        padding: 12px 24px;
        text-align: center;
        // letter-spacing: 0.2px;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 14px;
        font-family: poppins;
        border: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        // margin-right: 10px;
        // width: 48%;
    }
    .whatsappEnquire{
        color: white;
        border-radius: 8px;
        // margin-right: 20px;
        margin-left: 30px;
        background: rgb(41, 178, 0);

        img{
            height: 20px;
            width: auto;
            margin-right: 10px;
        }
    }
}
.seeMoreSkeletonnWrapper{
    padding: 15px 30px; 
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    .seeMoreSection1{
        width: 36%;
        .longDiv{
            height: 600px;
            width: 100%;
        }
    }

    .seeMoreSection2{
        width: 62%;
        .wideDiv{
            width: 100%;
            height: 100px;
            margin-bottom: 30px;
        }

        .seeMoreSection2a{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .seeMoreDivs{
                width: 24%;
                height: 200px;
                margin-right: 1%;
            }
        }
    }
}



@media only screen and (max-width: 768px) {
    .dogViewWrapper{
        flex-direction: column;

        .mainInfo{
            width: 100%;
            padding: 0;

            .listerDetailWrapper{
                box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                text-transform: capitalize;
                border-radius: 12px;
                padding: 20px !important;
                margin-bottom: 30px;

                img{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }

                .whatsappEnquire{
                    margin-left: 30px;
                    background: rgb(41, 178, 0);
                    font-size: 0.8rem;
            
                    img{
                        height: 20px;
                        width: auto;
                        margin-right: 10px;
                    }
                }
                
                p{
                    font-size: 1em;
                    font-weight: 600;
                }

                
            }
            
            .directInfoWrapper{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: start;
                margin-bottom: 20px;
                margin-top: 60px;
    
                &.scores{
                    color: #fff;
                    position: relative;
                    align-items: stretch;
                    margin-top: 100px;
    
                    .directInfo{
                        position: relative;
                        padding: 50px 20px 20px;
                        margin-bottom: 100px;
                        background: #6504b5;
                        width: 100%;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                        border-radius: 12px;
    
                        img{
                            position: absolute;
                            top: -40%;
                            height: 100px;
                            width: auto;
                            left: 50%;
                            transform: translateX(-50%) scale(1.6);
                        }
    
                        p{
                            margin-bottom: 0;
                            letter-spacing: 1px;
                            font-size: 1rem;
                            font-family: poppins;
                            font-weight: 700;
                            margin-bottom: 10px;
                        }
                    }
    
                }
    
                .directInfo{
                    width: 100%;
                    margin-bottom: 15px;
                    text-align: center;
                    padding: 10px;
    
                    & p{
                        font-family: poppins;
                        text-transform: capitalize;
                        font-size: 0.8rem;
                        margin-bottom: 30px;
                    }
    
                    .dogDetailIcon{
                        font-size: 2em;
                    }
    
    
                    .InfoHeader{
                        font-size: 0.9rem;
                        font-family: durea;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        margin-top: 10px;
                        margin-bottom: 8px;
                    }
                    span{
                        font-weight: 600;
                    }
                }
            }
            .descriptionWrapper{
                margin-bottom: 40px;
                h4{
                    font-size: 20px;
                    font-family: changa one;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    margin-bottom: 15px;
                }
            }
        }

        .galleryWrapper{
            position: relative;
            width: 100%;
            padding: 0;
            top: 0;
            margin-bottom: 30px;
        }

    }
    .seeMoreSkeletonnWrapper{
        padding: 15px 30px; 
        width: 100%
    }


    .seeMoreSkeletonnWrapper{
        padding: 15px 30px; 
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    
        .seeMoreSection1{
            width: 100%;
            .longDiv{
                height: 400px;
                width: 100%;
            }
        }
    
        .seeMoreSection2{
            width: 100%;
            .wideDiv{
                width: 100%;
                height: 100px;
                margin-bottom: 30px;
            }
    
            .seeMoreSection2a{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
    
                .seeMoreDivs{
                    width: 48%;
                    min-width: 48%;
                    height: 150px;
                    margin-right: 2%;
                    margin-bottom: 20px;
                }
            }
        }
    }
}