.vetSideCardWrapper{
    font-family: changa one ;
    position: relative;
    margin-top: 20px;
    background: #6504b5;
    border-radius: 12px;
    padding-bottom: 20px;
    color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    h1{
        padding: 0 20px;
        font-size: 1.8rem !important;
        margin-top: 10px;
    }

    p{
        font-family: poppins;
        padding: 0 20px;
        font-size: 0.8rem;
    }
    button{
        font-family: durea;
        text-transform: uppercase;
        font-size: 1rem !important;
        margin: 0 20px;
        letter-spacing: 0.5px;
    }
    img{
        width: 80px;
        height: auto;
        scale: 2;
        margin-bottom: 40px;
        margin-left: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .vetSideCardWrapper{
    margin-top: 40px;
    }
}