.enquiryImage{
    height: 300px;
}
.enquiryModal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

p.modalP{
    margin-bottom: 30px;
}

a.landingButtonMain{
    &.adopt.secondary{
        text-decoration: none;
    }
}