.bookingPickerWrapper{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: start;

    .pickerWrapper{
        width: 50%;
    }

    .slotWrapper{
        flex-grow: 1;
        max-width: 50%;
        // max-width: 500px;
        text-align: center;
        padding: 0 0px 20px;

        

        h4{
            font-family: poppins;
            font-weight: 700;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        .timeSlot{
            width: 100%;
            border-radius: 30px;
            border: 1px solid #6504b5;
            color: #6504b5;
            font-family: poppins;
            padding: 10px 20px;
            margin-bottom: 10px;

            &.active{
                border: 1px solid #6504b5;
                background: #6504b5;
                color: #fff;
            }
        }

        .confirmButton{
            font-family: helvetica;
            font-weight: 600;
            padding: 15px 20px;
            margin-top: 30px;
            border-radius: 12px;
            background: #6504b5;
            color: #fff;
            border: 1px solid #6504b5;
        }
    }
}

@media only screen and (max-width: 768px) {
    .bookingPickerWrapper{
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    
        .pickerWrapper{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
        }
    
        .slotWrapper{
            flex-grow: 1;
            max-width: 100%;
            width: 100%;
            // max-width: 500px;
            text-align: center;
            padding: 0 0px 20px;
    
            
    
            h4{
                font-family: poppins;
                font-weight: 700;
                font-size: 0.8rem;
                margin-bottom: 20px;
                text-transform: uppercase;
            }
    
            .timeSlot{
                width: 100%;
                border-radius: 30px;
                border: 1px solid #6504b5;
                color: #6504b5;
                font-family: poppins;
                font-size: 0.8rem;
                padding: 10px 20px;
                margin-bottom: 10px;
    
                &.active{
                    border: 1px solid #6504b5;
                    background: #6504b5;
                    color: #fff;
                }
            }
    
            .confirmButton{
                font-family: poppins;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                font-size: 0.8rem;
                font-weight: 600;
                padding: 15px 20px;
                margin-top: 10px;
                border-radius: 12px;
                background: #6504b5;
                color: #fff;
                border: 1px solid #6504b5;
            }
        }
    }

}