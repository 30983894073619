.serviceSkeletonMainWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 0 30px;

    .skeletonBoxMain{
        width: 600px;
        height: 50px;
        margin-bottom: 30px;
    }
}

.serviceSkeletonWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: stretch;
    flex-wrap: wrap;


    .serviceSkeleton{
        width: 24%;
        margin-right: 1%;

        .skeletonImage{
            width: 80px;
            height: 80px;
            margin: 10px 0;
        }

        .skeletonBox{
            width: 100%;
            height: 200px;
            margin: 10px 0;
        }

        .skeletonActionWrapper{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;

            .skeletonButton{
                width: 48%;
                height: 40px;
            }
        }
    }
}



@media only screen and (max-width: 768px) {
    .serviceSkeletonMainWrapper{
        padding: 0 20px;
        margin-top: 20px;
        .skeletonBoxMain{
            width: 300px;
            height: 40px;
            margin-bottom: 30px;
        }
    }
    .serviceSkeletonWrapper{
        flex-direction: column;
        justify-content: start;
        align-items: start;

        .serviceSkeleton{
            width: 100%;
            margin-right: 0;
            margin-bottom: 50px;
        }
    }

}