.userTabsWrapper{
    padding: 0 30px;
    max-width: 100%;
}
.userAccountWrapper{
    font-family: poppins;
    max-width: 100%;
    h2{
        font-family: changa one;
        margin-bottom: 20px;
    }
    .MuiTypography-root{
        font-family: poppins;
    }
    #vertical-tabpanel-0{
        flex-grow: 1;

        & > div{
            padding: 24px 10px 24px 10px !important;
        }
    }
    #vertical-tabpanel-1{
        flex-grow: 1;
        & > div{
            padding: 24px 10px 24px 10px !important;
        }
    }
    #vertical-tabpanel-2{
        flex-grow: 1;
        & > div{
            padding: 24px 10px 24px 10px !important;
        }
    }
    .MuiTabs-root{
        min-width: 100px; ;
    }
    .MuiTabs-scroller button{
        font-family: poppins;
    }
    .profileForm{
        .formRow{
            display: flex;
            justify-content: space-between;
            div{
                width: 49%;
                display: flex;
                flex-direction: column;
            }
            input, select{
                padding: 12px;
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }
        &.security{
            .formRow{
                div{
                    width: 32%;
                }
            }
        }
    }
    .petSection{
        width: 100%;
        .sectionHeader{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            h2{
                margin: 0;
            }
        }
        .addDog{
            background: #6504B5;
            color: #fff;
            padding: 10px 15px;
            border-radius: 30px;
            font-family: poppins;
            font-weight: 500;
            box-shadow: 0px 3px 15px rgba(0,0,0,0.2); 
            border: 0px;
        }

        .dogWrapper{
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: start;
        }
        
        .addDogCard{
            background: #6504B5;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24%;
            text-align: center;
            font-family: changa one;
            border-radius: 8px;
            margin-bottom: 20px;
            font-size: 20px;
            margin-right: 1%;
            cursor: pointer;
            padding: 40px;
            opacity: 0.8;
        }
    }
}


@media only screen and (max-width: 768px) {
    .MuiTabs-root{
        overflow: visible!important;
    }
    .userTabsWrapper{
        padding: 0 10px;
        max-width: 100%;
    }
    .userAccountWrapper{
        margin-top: 20px;
        font-family: poppins;
        max-width: 100%;
        #vertical-tabpanel-0{
            flex-grow: 1;
            padding-bottom: 52px;
        }
        #vertical-tabpanel-1{
            flex-grow: 1;
        }
        #vertical-tabpanel-2{
            flex-grow: 1;
        }
        h2{
            font-family: changa one;
            margin-bottom: 20px;
        }
        .MuiTypography-root{
            font-family: poppins;
        }
        
        .MuiTabs-scroller button{
            font-family: poppins;
            font-size: 12px;
        }
        .profileForm{
            .formRow{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-size: 12px;
                div{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }
                input, select{
                    padding: 12px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    max-width: 100%;
                }
            }
            &.security{
                .formRow{
                    div{
                        width: 100%;
                    }
                }
            }
        }
        .petSection{
            width: 100%;
            .sectionHeader{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
    
                h2{
                    margin: 0;
                }
            }
            .addDog{
                background: #6504B5;
                color: #fff;
                padding: 10px 15px;
                border-radius: 30px;
                font-family: poppins;
                font-weight: 500;
                box-shadow: 0px 3px 15px rgba(0,0,0,0.2); 
                border: 0px;
            }
            .addDogCard{
                background: #6504B5;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                text-align: center;
                font-family: changa one;
                border-radius: 8px;
                margin-bottom: 20px;
                font-size: 20px;
                margin-right: 1%;
                cursor: pointer;
                padding: 20px 40px;
                opacity: 0.8;
            }
        }
    }
    .profilePetSection{
        font-size: 12px;
    }
}