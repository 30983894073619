.matingPopupWrapper{
    display: flex;
    justify-content: center;
    align-items: center;

    .matingPopup{
        width: 50%;
        border-radius: 8px;
        border: 0px;
        background: #fff;
        padding: 3px;
        text-align: center;

        
            // width: 32%;
            // margin-right: 1%;
        
        .dogPhotoWrapper{
            height: 220px;
            width: 100%;
        }
        .dogDetailMating{
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            padding: 20px;
    
            .matchActionWrapper{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
    
            div{
              display: flex;
              align-items: center;
              margin: 5px 0;
              margin-right: 30px;
    
              h3{
                margin-bottom: 0;
              }
          
              p{
                margin: 0;
              }
          
              img{
                width: 20px;
                margin-right: 10px;
              }
            }
          }
          
        .matingDescWrapper{
            text-align: left;
            padding: 20px;

            p{
                font-family: poppins;
            }
         }

        .actionWrapper{
            width: 100%;

            a{
                width: 100%;
                text-decoration: none;
            }

            .whatsappEnquire{
                width: 100%;
                border-radius: 0;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
  .matingPopupWrapper{
    display: flex;
    justify-content: center;
    align-items: center;

    .matingPopup{
        width: 90%;
        border-radius: 8px;
        border: 0px;
        background: #fff;
        padding: 3px;
        text-align: center;

        
            // width: 32%;
            // margin-right: 1%;
        
        .dogPhotoWrapper{
            height: 220px;
            width: 100%;
        }
        .dogDetailMating{
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            padding: 20px;
    
            .matchActionWrapper{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
    
            div{
              display: flex;
              align-items: center;
              margin: 5px 0;
              margin-right: 30px;
    
              h3{
                margin-bottom: 0;
              }
          
              p{
                margin: 0;
              }
          
              img{
                width: 20px;
                margin-right: 10px;
              }
            }
          }
          
        .matingDescWrapper{
            text-align: left;
            padding: 20px;

            p{
                font-family: poppins;
            }
         }

        .actionWrapper{
            width: 100%;

            a{
                width: 100%;
                text-decoration: none;
            }

            .whatsappEnquire{
                width: 100%;
                border-radius: 0;
                margin-left: 0px;
                border-radius: 8px;
            }
        }
    }
}

}
