// .pupListWrapper.mating{
//     overflow: hidden;
// }

.poochkuPawMatchWrapper{
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.matingWrapper{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: start;
    width: 100%;
    padding: 0 0px;
    height: 100%;
    // overflow-x: hidden;
    overflow-y: scroll;

    .MatingSection{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      padding: 0 30px;
      max-width: 75%;
      width: 75%;

      .matingTabs{
        // height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 8px;
        background: #fff;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

        div{
          font-size: 20px;
          font-family: changa one;
          &:nth-child(1){
            margin-right: 10px;
          }
          cursor: pointer; 
          padding: 15px;
          transition: 0.5s;
          &.active{
            background: #6504b5;
            scale: 1.1;
            color: #fff;
            box-shadow: 0px 0px 5px 0px #b6b6b6;
            border-radius: 8px;
          }
        }
      }
    }

    .matingPanel{
      width: 100%;
      display: flex;
      align-items: start;
      justify-content: center;
      // background: #fff;
      // box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
      padding: 0 0px;
    }

    .matchSection{
      width: 100%;
      
      // overflow-y: scroll;
      position: relative;

      h3{
        position: sticky;
        top: 0;
        font-family: changa one;
        padding: 15px 0;
        background: #fff;
      }

      .match{
        padding: 15px 20px;
        margin-bottom: 20px;
        border-radius: 8px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

        .dogPhotoWrapper{
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }
        .dogDetailMating{
          font-size: 14px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 20px;
  
          .matchActionWrapper{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 100%;
          }
  
          div{
            display: flex;
            align-items: center;
            margin: 5px 0;
            margin-right: 30px;
  
            h3{
              margin-bottom: 0;
            }
        
            p{
              margin: 0;
            }
        
            img{
              width: 20px;
              margin-right: 10px;
            }
          }
        }
        
        .actionWrapper{
          display: flex;
            justify-content: space-around;
            button{
                padding: 10px 20px;
                // letter-spacing: 0.2px;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 12px;
                font-family: poppins;
                border: 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 8px;
                box-shadow: 0px 3px 15px rgba(0,0,0,0.2)
            }
            .whatsappEnquire{
                background: #6504b5;
                color: white;
                margin-right: 20px;
                box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
                border-radius: 8px;

                &.pending{
                  background: rgba(101, 4, 180,0.5);
                }

                img{
                    height: 20px;
                    width: auto;
                    margin-right: 10px;
                }
            }
        }
      }
    }

    .card {
      font-family: poppins;
        position: relative;
        width: 100%;
        // max-width: 260px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;        
        overflow: hidden;
        border-radius: 12px;
      }
      .swipe {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) !important;
        width: 80%;
      }

      .cardContainer{
        padding: 30px 60px 30px 0;
        height: auto;
        width: 400px;
        overflow: hidden;
        position: relative;

        .dogPhotoWrapper{
          height: 400px;
          width: 100%;
        }

        .dogDetailMating{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 10px 10px;
          background: rgba(255,255,255,0.7);
          color: black;
          h3{
            font-size: 22px;
            color: #6504b5;
          }
          div{
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            p{
              margin-bottom: 0;
              font-weight: 500;
            }
            img{
              width: 20px;
              height: auto;
              margin-right: 15px;
            }
          }
        }
      }
}

.matingSelector{
  flex-grow: 1;
  width: 15%;
  max-width: 400px;
  padding: 20px;
  position: sticky;
  top: 0px;
  left: 0;
  
  
  h2{
    font-family: changa one;
    margin-bottom: 20px;
  }
}

.matingListWrapper{
  flex-grow: 1;
  padding: 0px ;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
}

.matingButtons{
  position: relative;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  img{
    width: 50px;
    height: 50px;
  }
  .leftSwipe{
    // background: red;
    border-radius: 50%;
  }
  .rightSwipe{
    // background: green;
    border-radius: 50%;
  }

    button{
      padding:0px;
      border-radius: 12px;
      border: 0px ;
      color: white;
      background: #fff;
      box-shadow: 0px 0px 5px 0px #b6b6b6;
    }
}
.addMatingDog{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img{
    width: 250px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  h1{
    margin-bottom: 30px;
  }
}

.crossIcon{
  height: 80px;
  width: auto;
  margin: 0px 60px;
  opacity: 0.4;
}

.skeletonCardWrapperMating{
  display: flex;
  width: 100%;
  justify-content: start;

  span{
    border-radius: 12px;
  }

  .matingSkeleton1{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 25%;

    span{
      margin-bottom: 20px;
    }
  }

  .matingSkeleton2Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 75%;
    padding: 0 40px;

    .matingSkeleton2Section{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: start;
    }

    .matingSkeleton2{
      width: 31%;
      margin-right: 20px;
      border-radius: 12px;
      overflow: hidden;

      div{
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}


.nothingWrapper{
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  img{
    width: 200px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .addMatingDog{
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      width: 100px;
      margin-top: 20px;
      margin-bottom: 15px;
    }
    h1{
      margin-bottom: 30px;
    }
  }

  .skeletonCardWrapperMating{
    flex-direction: column;
    .matingSkeleton1{
      width: 100%;
      .matingSkeleton1a{
        height: 40px !important;
        width: 50% !important;
      }
      .matingSkeleton1b{
        height: 60px !important;
        width: 100% !important;
      }
    }
    .matingSkeleton2Wrapper{
      width: 100%;
      padding: 0;
      .matingSkeleton2Section{
        display: flex;
        flex-direction: column;
        .matingSkeleton2{
          width: 100%;
          margin-bottom: 30px;

          &:nth-child(2){
            display: none;
          }
        }
      }
    }
  }
  
  .matingWrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    overflow-x: hidden;
    overflow-y: visible;
    max-height: none;
    padding: 0 0px 30px;

    .MatingSection{
      width: 100%;
      max-width: 100%;
      padding: 0 20px;

      .matingTabs{
        // height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  
        div{
          margin-right: 0px;
          font-size: 14px;
          font-family: changa one;
          cursor: pointer; 
          padding: 10px;
          width: 50%;
          text-align: center;
          transition: 0.5s ;
          &.active{
            background: #6504b5;
            scale: 1.1;
            color: #fff;
            box-shadow: 0px 0px 5px 0px #b6b6b6;
            border-radius: 8px;
          }
        }
      }
    }

    .font-face-D{
      font-size: 0.9rem !important;
    }

    .matingPanel{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      background: none;
      box-shadow: 0px ;
      padding: 30px 0px 20px;
    }

    .matchSection{
      width: 100%;
      max-width: 100%;
      background: none;
      padding: 0px 0px 20px;
      overflow-y: visible;
      position: relative;
      // box-shadow: 0px 3px 15px rgba(0,0,0,0.2);

      h3{
        position: sticky;
        top: 0;
        font-family: changa one;
        padding: 15px 0;
        background: #fff;
      }

      .match{
        padding: 10px;
        margin-bottom: 20px;
        padding-top: 20px;
        border-radius: 8px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

        .dogPhotoWrapper{
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-bottom: 20px;
        }
        h6{
          margin-bottom: 20px;
        }
        .dogDetailMating{
          font-size: 12px;
          width: 100%;
          justify-content: space-between !important; 
          div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            margin-right: 0;

            p{
              margin: 0;
            }

            img{
              width: 20px;
              margin-right: 0px;
              margin-bottom: 10px;
            }
          }
        }
        .actionWrapper{
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          margin-top: 0px;
          justify-content: space-between;
            button{
                padding: 10px 20px;
                // letter-spacing: 0.2px;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 12px;
                font-family: poppins;
                border: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                box-shadow: 0px 3px 15px rgba(0,0,0,0.2)
            }
            .whatsappEnquire{
                background: rgb(41, 178, 0);
                color: white;
                margin-right: 0px;
                margin-bottom: 0px;
                margin-left: 0;
                box-shadow: 0px 3px 15px rgba(0,0,0,0.2);

                &.pending{
                  background: rgba(101, 4, 180,0.5);
                }

                img{
                    height: 20px;
                    width: auto;
                    margin-right: 10px;
                }
            }
        }
      }
    }

    .card {
      font-family: poppins;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 100%;
        // max-width: 260px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;        
        border-radius: 12px;
        overflow: hidden;
      }
      .swipe {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) !important;
        width: 80%;
      }

      .cardContainer{
        padding: 0px 0px 30px;
        height: 400px;
        width: 100%;
        // overflow: hidden;
        position: relative;

        .dogPhotoWrapper{
          height: 300px;
          width: 100%;
        }

        .dogDetailMating{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 10px 10px;
          background: rgba(255,255,255,0.7);
          color: black;
          h3{
            font-size: 22px;
            color: #6504b5;
          }
          div{
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            p{
              margin-bottom: 0;
              font-weight: 500;
            }
            img{
              width: 20px;
              height: auto;
              margin-right: 15px;
            }
          }
        }
      }
  }
  .matingButtons{
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    // margin-top: 30px;
    img{
      width: 50px;
      height: 50px;
    }
    .leftSwipe{
      // background: red;
      border-radius: 50%;
    }
    .rightSwipe{
      // background: green;
      border-radius: 50%;
    }
  
      button{
        // padding:15px;
        border-radius: 12px;
        border: 0px ;
        color: white;
        // background: #6504b5;
      }
  }
  
  .crossIcon{
    height: 80px;
    width: auto;
    margin: 20px 60px;
  }

  .matingSelector{
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    padding: 20px;
    top: 0;

    .vetSideCardWrapper{
      display: none;
    }
    
    h2{
      font-family: changa one;
      margin-bottom: 20px;
    }
  }

  .matingListWrapper{
    flex-grow: 1;
    width: 100%;
    padding: 0px ;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
  }
}