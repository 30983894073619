.vetCardWrapper{
    max-width: 24%;
    width: 24%;
    margin-bottom: 80px;
    margin-right: 1%;
    background: white;
    box-shadow: 0px 0px 5px 0px #b6b6b6;
    padding: 10px 10px 0;
    border-radius: 12px;
    // font-size: 12px;
    
    p{
        font-size: 0.8rem;
    }

    h3{
        font-family: poppins;
        font-weight: 700;
        font-size: 0.9rem;
        text-overflow: ellipsis; /* enables ellipsis */
        white-space: nowrap; /* keeps the text in a single line */
        overflow: hidden;
        margin-top: 10px;
    }
    
    .vetImage{
        width: 100%;
        height: 30px;
        margin-top: 20px;
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    
        img{
            border-radius: 50%;
            border: 0px solid #6504b5;
            box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
            height: 100px;
            width: 100px;
            position: absolute;
            bottom: 0;
            left: 20px;
            background: #fff;
        }
    }
    .vetCard{
        padding: 15px 15px 0px;

        .vetReviews{
            margin-bottom: 0px!important;
            margin-top: 10px !important;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;

            img{
                width: 18px;
                height: 18px;
                margin-right: 10px;
            }

            p{
                margin: 0;
                color: #444444;
                text-decoration: underline;
                font-size: 0.8rem;
            }

        }

        .vetAddress, .vetQualification{
            text-overflow: ellipsis; /* enables ellipsis */
            white-space: nowrap; /* keeps the text in a single line */
            overflow: hidden;
            font-size: 0.8rem;
        }
        
        .vetExperience, .vetAddress , .vetQualification, .vetVerification, .vetReviews{
            font-size: 0.8rem;
            margin: 0 0 2px;
            color: #a7a7a7;
        }

        .vetCallLink{
            display: flex;
            justify-content: start;
            align-items: center;
            font-size: 0.8rem;
            text-decoration: none;
            color: #444444;
            margin: 15px 0 0 0;
            font-weight: 600;

            .reviewIcon{
                font-size: 1.2rem;
                margin-right: 5px;
                color: #6504b5;
            }
        }
    }
}

.bookingButton{
    margin-top: 20px;
    padding: 15px 20px;
    font-family: poppins;
    text-transform: uppercase;
    font-weight: 700;
    background: #6504b5;
    color: #fff;
    border: 1px solid #6504b5;
    text-decoration: none;
    border-radius: 8px;
    font-size: 12px;
    margin-right: 10px;

    &.call{
        background: none;
        color: #6504b5;
        margin-right: 0px;
        font-size: 12px;
        padding: 15px 20px;
    }

    &.secondary{
        color: #6504b5;
        background: #fff;
        border: 1px solid #fff;

        &:hover{
            color: #6504b5;
        }
    }

    &:hover{
        // box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    }
}


@media only screen and (max-width: 768px) {
    .vetCardWrapper{
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 50px;

        &:nth-child(1){
            margin-top: 20px;
        }

        .vetImage{
            height: 20px;
            margin-top: 20px;    
            width: 100%;
            position: relative;
            background: #fff;
    
            img{
                border-radius: 50%;
                border: 2px solid white;
                height: 100px;
                width: 100px;
                position: absolute;
                bottom: 0;
                left: 20px;
            }
        }
    }
}