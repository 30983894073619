.popupWrapper{
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiModal-backdrop{
        background-color: rgba(0, 0, 0, 0.8) !important;
    }

    img{
        width: 150px;
        height: auto;
        position: relative;
        transform: translateY(-60%) scale(2);
    }

    .popUp{
        width: 50%;
        border-radius: 8px;
        border: 0px;
        background: #6504b5;
        color: #fff;
        padding: 20px 20px 30px;
        position: relative;
        text-align: center;

        .modalActions{
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
            cursor: pointer;
            svg{
                z-index: 10;
            }
        }

        .heading{
            font-size: 2rem;
            // font-weight: 700;
            font-family: changa one;
            margin-bottom: 5px;
        }

        .subHeading{
            font-family: poppins;
            margin-bottom: 15px;
        }

        .popupActions{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 30px;

            button, a{
                padding: 10px 20px;
                border: 1px solid #fff;
                border-radius: 8px;
                color: #fff;
                background: #6504b5;
                font-weight: 700;
                font-family: poppins;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 0.5px;
            }

            .formButton{
                text-transform: uppercase;
                margin-right: 15px;
                background: #fff;
                color: #6504b5;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .popupWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        .popUp{
            width: 90%;

            .heading{
                font-size: 1rem;
                // font-weight: 700;
                font-family: changa one;
                letter-spacing: 1px;
            }

            .popupActions{
                
                button, a{
                    padding: 10px 20px;
                    border: 1px solid #fff;
                    border-radius: 8px;
                    color: #fff;
                    background: #6504b5;
                    font-family: poppins;
                    font-weight: 700;
                    text-transform: uppercase;
                    text-decoration: none;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
}