.browsePetWrapper{
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: poppins;
    
    .pupListWrapper{
        width: calc(100% - 220px);
        z-index: 10;
        padding: 0px 0px 20px;
        background: #f5f5f5;
        // box-shadow: -5px 0px 5px 0px #bebebe;
        box-shadow: 0px 0px 5px 0px #b6b6b6;


        .pageHeadingSticky{
            position: sticky;
            top: -1px;
            padding: 35px 30px 15px;
            background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(101,4,181,1) 100%);;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: stretch;
            flex-wrap: wrap;
            z-index: 10;
            color: #fff;
            // box-shadow: 0 0 10px 0px rgba(0,0,0,0.5);

            .mobileHomeLink{
                display: none;
            }

            .userMenu{
                display: none;
            }

            &.nonStick{
                position: relative;
            }

            h1{
                margin-bottom: 10px;
                font-family: changa one;
            }

            // input{
            //     margin: 0 10px;
            // }

            // select, button{
            //     margin: 0 20px;
            // }

            
            .buyPageHeading{
                font-family: changa one;
            }
            .buyPageInfo{
                font-family: poppins;
                margin-bottom: 0;
            }
        }
        .buyPageListWrapper{
            padding: 0 30px;
            display: flex;
            flex-direction: column;
            position: relative;
            justify-content: space-between;
            align-items: start;
            
            .buyPageList{
                width: 80%;
                display: flex;
                flex-wrap: wrap;
                align-items: start;
                justify-content: start;
                position: relative;
            }
            .bannerWrapper{
                width: 19%;
                position: sticky;
                top:150px;
                padding: 20px 15px 25px;
                text-align: center;
                // background: #2e0152;
                // background: #6504b5;
                color: white;
                // border: 1px solid #ffd903;
                border-radius: 12px;
                h3{
                    font-family: changa one;
                }
                p{
                    font-family: poppins;
                }
                button{
                    margin-top: 10px;
                    padding: 10px 20px;
                    font-family: changa one;
                    background: #fff;
                    color: #6504b5;
                    border: 0px;
                    border-radius: 8px;
                }
            }
        }
        .buySection, .adoptionSection{
            display: flex;
            justify-content: space-between;
            align-items: start;
            width: 100%;
        }
    }
}
.pageSelector{
    margin-bottom: 40px;
    position: relative;
    padding: 0 30px;
    // top: 200px;
    .selectorButton{
        margin-top: 10px;
        padding: 20px 40px;
        font-family: changa one;
        color: rgb(2, 0, 36);
        background: none;
        border: 1px solid rgb(2, 0, 36);
        border-radius: 8px;
        margin-right: 20px;
        box-shadow: 0px 0px 5px 0px #b6b6b6;

        &.active{
            background: rgb(2, 0, 36);
            border: 1px solid rgb(2, 0, 36);
            color: #fff;
        }
    }
}
.enquiryModal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    border-radius: 12px;
    padding: 40px;
    background: #6504B5;
    color: #fff;    
    font-family: poppins;
}

.mainPageHr{
    margin: 0px 30px 25px;
}

.paginationWrapper{
    position: sticky;
    padding: 20px;
    background: #f5f5f5;
    bottom: 0px;
    z-index: 1;
    left: 0;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .mainPageHr{
        display: none;
    }
    
    .browsePetWrapper{
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-family: poppins;
        min-height: 100vh;

        &.mating{
            min-height: 100vh;
        }
        
        .pupListWrapper{
            width:100%;
            position: relative;
            padding: 0px 0px 0;
            background: #f5f5f5;
    
            .pageHeadingSticky{
                position: relative;
                // top: -1px;
                padding: 15px 15px 15px;
                background: #fff;
                margin-bottom: 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                z-index: 10;
                color: #000;
                // box-shadow: 0 0 10px 0px rgba(0,0,0,0.5);

                .mobileHomeLink{
                    display: block;
                    img{
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }
                }

                .userMenu{
                    display: block;
                    img{
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                    }
                }
    
                &.nonStick{
                    position: relative;
                }
        
                // input{
                //     margin: 0 10px;
                // }
    
                // select, button{
                //     margin: 0 20px;
                // }
    
                
                .buyPageHeading{
                    font-family: changa one;
                    font-size: 1.1rem !important;
                    margin-bottom: 0px;
                    text-align: center;
                }
                .buyPageInfo{
                    font-family: poppins;
                    font-size: 0.8rem !important;
                    text-align: center;
                }
            }
            .buyPageListWrapper{
                padding: 0 15px;
                display: flex;
                flex-direction: column;
                position: relative;
                justify-content: space-between;
                align-items: start;
                padding-bottom: 60px;

                .buyPageList{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: start;
                    justify-content: start;
                    position: relative;
                }
                .bannerWrapper{
                    width: 100%;
                    position: sticky;
                    top:150px;
                    padding: 0px 0px 25px 0;
                    text-align: center;
                    // background: #2e0152;
                    // background: #6504b5;
                    color: white;
                    border-radius: 12px;
                    h3{
                        font-family: changa one;
                    }
                    p{
                        font-family: poppins;
                    }
                    button{
                        margin-top: 10px;
                        padding: 10px 20px;
                        font-family: changa one;
                        background: #fff;
                        color: #6504b5;
                        border: 0px;
                        border-radius: 8px;
                    }
                }
            }

            .buySection, .adoptionSection{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: start;
                width: 100%;
            }
        }
    }
    .enquiryModal{
        width: 80vw;
        padding: 30px 20px;
    }
    .skeletonCardWrapper{
        width: 100%!important;
    }
    .paginationWrapper{
        position: sticky;
        padding: 10px 20px;
        background: #f5f5f5;
        bottom: 53px;
        z-index: 1;
        left: 0;
        width: 100%;
    }
}

.skeletonCardWrapper{
    width: 30%;
    .skeletonCard{
    margin-bottom: 20px;
    border-radius: 4px;
    }   
}