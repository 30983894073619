.addClinicFormWrapper {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    form {
      display: flex;
      flex-direction: column;
    }
    
    label {
      margin-bottom: 10px;
    }
    
    input, textarea {
      margin-top: 5px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    .servicesSection {
      margin-top: 20px;
    }
    
    button {
      margin-top: 20px;
      padding: 10px 15px;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    
    button:hover {
      background-color: #218838;
    }
  }
  
  