.matchCardWrapper{
    min-width: 31%;
    margin-right: 2%;
    margin-bottom: 30px;

    .dogPhotoWrapper{
        height: 220px;
        width: 100%;
      }
      .dogDetailMating{
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: 10px 20px;

        .matchActionWrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        div{
          display: flex;
          align-items: center;
          margin: 5px 0;
          margin-right: 0px;

          h3{
            margin-bottom: 0;
            font-size: 1rem !important;
          }
      
          p{
            margin: 0;
            font-size: 0.8rem;
          }
      
          img{
            width: 20px;
            margin-right: 10px;
          }
        }
      }

      .actionWrapper{
        width: 100%;

        a{
          width: 100%;
          text-decoration: none;
        }

        button{
          flex-grow: 1;
          max-width: 48%;
          font-size: 0.8rem !important;
        }

        .whatsappEnquire{
          border-radius: 8px;
          margin-left: 0;
        }
      }
  }


@media only screen and (max-width: 768px) {
  .matchCardWrapper{
    width: 100%;
    margin-right: 0%;

    .dogPhotoWrapper{
        height: 220px;
        width: 100%;
      }
      .dogDetailMating{
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: 20px;

        .matchActionWrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        div{
          display: flex;
          align-items: center;
          margin: 5px 0;
          margin-right: 0px;

          h3{
            margin-bottom: 0;
          }
      
          p{
            margin: 0;
          }
      
          img{
            width: 20px;
            margin-right: 10px;
          }
        }
      }

      .actionWrapper{
        width: 100%;

        a{
          width: 100%;
          text-decoration: none;
        }

        .whatsappEnquire{
          max-width: 48%;
          border-radius: 8px;
        }
      }
  }


}