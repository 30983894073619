.phone-filed{

    input{
        width: 300px !important;
        max-width: none;
    }

    button{

    }
}

.phone-container{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.otpVerificationWrapper_{
    div{
        flex-direction: row !important;

        input{
            width: 1.5rem !important;
        }

        svg.MuiCircularProgress-svg{
            margin-left: 0 !important;
        }
        
    }
}