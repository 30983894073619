.adoptPromptWrapper {
  width: 100%;
  display: flex;
  align-items: start;
  position: relative;
  justify-content: space-between;
  padding: 0 30px;
}
.adoptPromptWrapper .buyPageList {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
}
.adoptPromptWrapper .bannerWrapper {
  width: 19%;
  position: sticky;
  top: 150px;
  padding: 20px 15px 25px;
  text-align: center;
  background: #6504b5;
  color: white;
  border: 1px solid #6504b5;
  border-radius: 12px;
}
.adoptPromptWrapper .bannerWrapper h3 {
  font-family: changa one;
}
.adoptPromptWrapper .bannerWrapper p {
  font-family: poppins;
}
.adoptPromptWrapper .bannerWrapper button {
  padding: 10px 20px;
  margin-top: 10px;
  font-family: changa one;
  background: #fff;
  color: #6504b5;
  border: 0px;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .adoptPromptWrapper {
    width: 100%;
    display: block;
    position: relative;
    padding: 0 15px;
    padding-bottom: 60px;
  }
  .adoptPromptWrapper .buyPageList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: start;
  }
  .adoptPromptWrapper .bannerWrapper {
    width: 100%;
    position: sticky;
    top: 150px;
    padding: 20px 15px 25px;
    text-align: center;
    background: #6504b5;
    color: white;
    border: 1px solid #6504b5;
    border-radius: 12px;
  }
  .adoptPromptWrapper .bannerWrapper h3 {
    font-family: changa one;
  }
  .adoptPromptWrapper .bannerWrapper p {
    font-family: poppins;
  }
  .adoptPromptWrapper .bannerWrapper button {
    padding: 10px 20px;
    margin-top: 10px;
    font-family: changa one;
    background: #fff;
    color: #6504b5;
    border: 0px;
    border-radius: 8px;
  }
}/*# sourceMappingURL=style.css.map */