.landingBanner{
    height: 90vh;
    max-width: 100%;
    background: url("../../../Assets/landingbanner.png");
    background-position: center;
    position: relative;
    background-size: cover;
    font-family: poppins;

    .mainSearch{
        font-size: 16px;
        padding: 20px;
        border-radius: 40px;
        border: 0px;
        width: 500px;
    }

    .landingActions{
        padding: 20px;
        position: absolute;
        top: 22vh;
        left: 5vw;
        color: #fff;
        
        .landingHeading{
            font-weight: 700;
            font-size: 120px;
            line-height: 120px;
            margin-bottom: 30px;
        }

        // button{
        //     font-family: changa one;
        //     padding: 20px 30px;
        //     // background: rgb(240, 82, 42);
        //     // border: 0px;
        //     // border-radius: 40px;
        //     // color: #fff;
        //     font-size: 20px;
        //     font-weight: 400;
        //     margin-top: 0;
        //     margin-right: 20px;

        //     &.finder{
        //         // background: #f5f5f5;
        //         // color: #6504B5;
        //     }
        // }
    }
}

.landingButtonMain{
    font-family: poppins;
    padding: 20px 30px;
    background: #6504B5;
    border: 1px solid #6504B5;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: white;
    margin-top: 0;
    margin-right: 20px;
    border-radius: 40px;
    letter-spacing: 0px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: all 0.2s;

    &:hover{
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    }

    &.secondary{
        background: white;
        // border: 1px solid #fea501;
        // color: #fea501;
        color: #6504B5;
        border: 1px solid #6504B5;
    }

    &.link{
        background-color: none;
        border: 1px solid #fff;
        color: #fff;
    }
}

.landingButtonMain.secondary.adopt{
    background: #fff;
    color: #6504B5;
    border: 1px solid #6504B5;
}

.landingIntro{
    background: #fbfbfb;
    padding: 50px 150px 100px;
    text-align: left;
    // height: 105vh;
    // background: url('../../../Assets/introBackground.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    img{
        width: auto;
        height: 200px;
        // transform: translateX(-20px);
        margin-bottom: 30px;
        border-radius: 50%;

        &.animatedSatyam{
            width: 40%;
            height: auto;
            margin-left: 0px;
            margin-bottom: 0;
        }
    }

    h1{
    font-family: poppins;
    font-weight: 700;
    font-size: 50px;
    letter-spacing: 1px;
    color: #333;
    margin-bottom: 20px;
    }

    div{
        font-family: changa one;
        font-size: 25px;
        flex-grow: 1;
        margin: 10vh 0 20px;
        letter-spacing: 1px;
        color: #6504B5;

        .introInfo{
            width: 100%;
            margin-top: 20px;
            font-family: poppins;
            color: #333;
            font-size: 14px;
            letter-spacing: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0;

            p{
                padding-left: 0px;
                text-align: justify;
            }
        }
    }
}

.landingBreak{
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(101,4,181,1) 100%);
    color: #fff;
    text-align: center;
    padding: 50px;
    h1{
        margin-bottom: 50px;
        font-family: changa one;
    }
    .cityWrapper{
        display: flex;
        justify-content: space-around;
        font-family: poppins;
        img{
            box-shadow: 0px 3px 15px rgba(0,0,0,0.2); 
            background: #fff;
            padding: 20px;
            border-radius: 50%;
            height: 100px;
            width: auto;
            margin-bottom: 10px;
        }
    }
}

.connectSection-hp{
    text-align: left;
}

.optionBoxWrapper{
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 50px 0;
    padding: 80px 150px;

    .connectSection-hp{
        flex-grow: 1;
        padding-right: 30px;
        
        p:nth-child(1){
            color: #6504B5;
            font-weight: 700;
            font-size: 25px;    
            margin-bottom: 20px;
            font-family: durea;
        }

        h5{
            font-size: 50px;
        }
    }

    .connectSectionImgWrapper{
        width: 100%;
        display: flex;
        justify-content: start;
        margin: 20px 0 40px;
    }

    .petSectionImg{
        width: 130px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border-radius: 50%;
        box-shadow: 0px 3px 15px rgba(0,0,0,0.2);

        &:nth-child(1){
            margin-right: 20px;
        }

        img{
            width: 100%;
            height: auto;
        }

    }

    .connectSectionImage{
        width: 40%;
        height: auto;
    }

    .optionBox{
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
        padding-top: 50px;
        
        .option{
            width: 49%;
            display: flex;
            justify-content: start;
            align-items: center;
            position: relative;
            padding: 0px;
            font-family: poppins;
            overflow: hidden;
            background: white;
            border-radius: 12px;
            margin-bottom: 30px;
            box-shadow: 0px 3px 15px rgba(0,0,0,0.2);


            .optionDetails{
                flex-grow: 1;
                padding: 20px 30px;
                h1{
                    margin-bottom: 10px;
                    font-family: changa one;
                }
            }

            img{
                width: 40%;
                height: auto;
                // max-height: 700px;
                // max-width: 50%;
                position: relative;
                // right: 0;
                // bottom: 0;
            }

            &:nth-child(1){
                width: 100%;
                
                img{
                    // position: relative;
                    height: auto;
                    width: 40%;
                }
            }
        }
    }
}

.optionBoxWrapper:nth-child(2){
    .connectSection-hp{
        flex-direction: row-reverse;
    }
}

.hp-shopSection, .hp-walkerSection{
    width: 100%;
    max-width: 100%;
    margin: 60px 0;

    img{
        width: 100%;
        height: auto;
    }
}

.adoptionSectionWrapperHP{
    padding: 80px 150px;
    text-align: center;

    p{
        color: #6504B5;
        font-weight: 700;
        font-size: 25px;    
        margin-bottom: 20px;
    }

    h1{
        margin-bottom: 40px;
        font-size: 50px !important;
    }

    .adoptionSectionHP{
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
    }
}

.articleSectionWrapper{
    background: #6504B5;
    padding: 100px 60px;
    color: #fff;

    h1{
        text-align: center;
        margin-bottom: 20px;
        font-family: changa one;
    }
    p{
        text-align: center;
        margin-bottom: 60px;
        font-family: poppins;
    }
    .articleSection{
        font-family: poppins;
        font-weight: 600;
        color: #6504B5;
        display: flex;
        justify-content: space-around;

        .articleCategory{
            text-align: center;
            width: 48%;
            height: 400px;
            background: url("../../../Assets/dogadoption.jpg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
            &:nth-child(2){
                background: url("../../../Assets/catadoption.jpg");
                background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            }

            div{
                position: absolute;
                bottom: 0;
                width: 100%;
                background: #fff;
                padding: 20px;
                img{
                    position: absolute;
                    top: 0;
                }
                p{
                    margin: 0;
                }
            }
        }
    }
}

.faqWrapper{
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(101,4,181,1) 100%);
    padding: 100px 60px;
    color: #fff;

    h1{
        text-align: center;
        margin-bottom: 20px;
        font-family: changa one;
        margin-bottom: 60px;
    }
    p{
        // text-align: center;
        font-family: poppins;
    }
}

.breederCallWrapper{
    background: #fbfbfb;
    display: flex;
    justify-content: center;
    background: url("../../../Assets/breederBanner.png") ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;
    margin-top: 0px;
    .breederCall{
        width: 70%;
        padding: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        h4{
            font-family: changa one;
        }
        p{
            font-family: poppins;
            margin-bottom: 0;
        }
        img{
            width: 70px;
            height: auto;
        }
    }
}

.helpSection{
    background: #fbfbfb;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 60px;
    font-family: poppins;
    div{
        width: 70%;
        padding: 10px;
    }
    img{
        width: 30%;
    }
}


@media only screen and (max-width: 768px) {
    .adoptionSectionWrapperHP{
        padding: 80px 15px;
        text-align: center;
    
        p{
            font-size: 1.2rem;    
        }
    
        h1{
            font-size: 2rem !important;
        }
    
        .adoptionSectionHP{
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: start;
            align-items: stretch;

            span{
                width: 100% !important;
            }
        }
    }

    .landingButtonMain{
        font-family: poppins;
        letter-spacing: 0.5px;
        padding: 15px 25px;
        background: #6504B5;
        border: 1px solid #6504B5;
        color: white;
        font-size: 0.8rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 0;
        margin-right: 20px;
        border-radius: 40px;
        letter-spacing: 0px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        transition: all 0.2s;
    
        &:hover{
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
        }
    
        &.secondary{
            background: white;
            // border: 1px solid #fea501;
            // color: #fea501;
            color: #6504B5;
            border: 1px solid #6504B5;
        }
    }
    .landingButtonMain.secondary{
        font-family: poppins !important;
        padding: 15px 25px;
        border: 1px solid #6504B5;
        color: #6504B5;
        font-size: 0.8rem;
        font-weight: 700;
        margin-top: 0;
        margin-right: 20px;
        border-radius: 40px;
    }

    .mainLandingButtonWrapper{
        flex-direction: row !important;
        justify-content: center !important;
    }
    
    .landingButtonMain.secondary.adopt{
        background: #fff;
        color: #6504B5;
        border: 1px solid #6504B5;
        font-family: poppins !important;
        margin-right: 0;
    }

    .landingBanner{
        height: 80vh;
        max-width: 100%;
        background: url("../../../Assets/landingbanner.png");
        background-position: right;
        position: relative;
        background-size: cover;
        font-family: poppins;
        background-repeat: no-repeat;

        .landingAction{
            .landingButtonMain{
                margin-left: 0;
                margin-top: 20px;
            }
        }
    
        .mainSearch{
            font-size: 12px;
            padding: 20px;
            border-radius: 40px;
            border: 0px;
            width: 300px;
        }
    
        .landingActions{
            padding: 20px;
            position: absolute;
            top: 15vh;
            left: 3vw;
            color: #fff;
            
            .landingHeading{
                font-weight: 700;
                font-size: 70px;
                line-height: 70px;
                margin-bottom: 30px;
            }
    
            button{
                font-family: poppins;
                padding: 20px 30px;
                // background: rgb(240, 82, 42);
                border: 0px;
                border-radius: 40px;
                // color: #fff;
                // font-size: 14px;
                // font-weight: 400;
                margin-top: 0;
                margin-left: 20px;
            }
        }
    }

    .landingIntro{
        background: #fbfbfb;
        text-align: center;
        padding: 50px 15px;
        // background: url('../../../Assets/introBackground.png');
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        img{
            width: 40%;
            height: auto;
            transform: translateX(0px);
            margin-bottom: 30px;
            border-radius: 50%;
    
            &.animatedSatyam{
                width: 90%;
                height: auto;
                margin-left: 0px;
                margin-bottom: 0;
            }
        }
    
        h1{
        font-family: poppins;
        font-weight: 700;
        font-size: 30px;
        letter-spacing: 1px;
        color: #333;
        margin-bottom: 10px;
        }
    
        div{
            font-family: changa one;
            font-size: 25px;
            width: 100%;
            margin-top: 6vh;
            letter-spacing: 1px;
            color: #6504B5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
    
            .introInfo{
                width: 100%;
                margin-top: 20px;
                font-family: poppins;
                color: #333;
                font-size: 14px;
                letter-spacing: 0px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
    
                p{
                    padding-left: 0px;
                    text-align: justify;
                }
    
                img{
                    width: auto;
                    height: 100px;
                }
            }
        }
    }

    .landingBreak{
        background: #6504B5;
        color: #fff;
        text-align: center;
        padding: 50px 20px;
        h1{
            margin-bottom: 50px;
            font-family: changa one;
        }
        .cityWrapper{
            display: flex;
            justify-content: space-around;
            font-family: poppins;
            flex-wrap: wrap;
            img{
                box-shadow: 0px 3px 15px rgba(0,0,0,0.2); 
                background: #fff;
                padding: 20px;
                border-radius: 50%;
                height: 90px;
                width: auto;
                margin-bottom: 10px;
            }
            p{
                margin-bottom: 25px;
            }
        }
    }

    .optionBoxWrapper{
        background: #f5f5f5;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px 15px;
        font-family: poppins;
        font-size: 0.9rem;

        .connectSection-hp{
            flex-grow: 1;
            padding-right: 0px;
            text-align: center;
            margin-bottom: 30px;
            
            p:nth-child(1){
                color: #6504B5;
                font-weight: 700;
                font-size: 25px;    
                margin-bottom: 20px;
                font-family: durea;
            }
    
            h5{
                font-size: 2rem;
            }
        }
    
        .connectSectionImgWrapper{
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 20px 0 40px;
        }

        .connectSectionImage{
            width: 100%;
            height: auto;
        }

        .optionBox{
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: start;
            flex-wrap: wrap;
            padding-top: 0px;
            
            .option{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: start;
                position: relative;
                padding: 0px;
                font-family: poppins;
                overflow: visible;
                background: white;
                border-radius: 12px;
                margin-bottom: 30px;
                box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
                .optionDetails{
                    width: 100%;
                    h1{
                        margin-bottom: 10px;
                        font-family: changa one;
                    }
                }
    
                img{
                    width: 100%;
                    height: auto;
                    // max-width: 50%;
                    position: relative;
                    right: 0;
                    bottom: 0;
                }
    
                &:nth-child(1){
                    width: 100%;
                    
                    img{
                        // position: relative;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    .articleSectionWrapper{
        background: #6504B5;
        padding: 80px 20px;
        color: #fff;
    
        h1{
            text-align: center;
            margin-bottom: 20px;
            font-family: changa one;
        }
        p{
            text-align: center;
            margin-bottom: 30px;
            font-family: poppins;
        }
        .articleSection{
            font-family: poppins;
            font-weight: 600;
            color: #6504B5;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
    
            .articleCategory{
                text-align: center;
                width: 100%;
                height: 400px;
                background: url("../../../Assets/dogadoption.jpg");
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
                &:nth-child(2){
                    background: url("../../../Assets/catadoption.jpg");
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    margin-top: 20px;
                }
    
                div{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    background: #fff;
                    padding: 20px;
                    img{
                        position: absolute;
                        top: 0;
                    }
                    p{
                        margin: 0;
                    }
                }
            }
        }
    }

    .breederCallWrapper{
        // background: #fbfbfb;
        background: url("../../../Assets/breederBanner.png") ;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 200px;
        display: flex;
        justify-content: center;
        .breederCall{
            width: 90%;
            padding: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            h4{
                font-family: changa one;
                margin-top: 20px;
            }
            p{
                font-size: 12px;
                font-family: poppins;
                margin-bottom: 0;
                margin-bottom: 20px;
            }
            img{
                width: 70px;
                height: auto;
            }
        }
    }

    .faqWrapper{
        background: #6504B5;
        padding: 50px 20px;
        color: #fff;
    
        h1{
            text-align: center;
            margin-bottom: 20px;
            font-family: changa one;
            margin-bottom: 20px;
        }
        p{
            // text-align: center;
            font-family: poppins;
            font-size: 12px;
        }
    }

    .helpSection{
        background: #fbfbfb;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
        font-family: poppins;
        font-size: 12px;
        div{
            width: 100%;
            padding: 10px;
        }
        img{
            width: 100%;
        }
    }
}