.searchWrapper{
    display: flex;
    justify-content: end;
    flex-grow: 1;
    margin-left: 40px;
    .filterInput{
        border: 0px;
        border-bottom: 1px solid rgba(255,255,255,0.9);
        background: none;
        font-size: 15px;
        padding: 5px 0px;
        font-family: changa one;
        margin: 0;
        margin-right: 30px;
        text-transform: uppercase;
        color: #fff;
        outline: 0;
        cursor: pointer;
        letter-spacing: 0.5px;
    }
    option{
        color: #000;
        padding: 5px;
        text-transform: uppercase;
    }
    .filterButton{
        padding: 15px 30px;
        border-radius: 30px;
        background: #fff;
        border: 1px solid #6504b5;
        color: #6504b5;
        font-family: poppins;
        font-weight: 600;
        letter-spacing: 0.3px;
        margin: 0;
    }
}

@media only screen and (max-width: 768px) {
    .searchWrapper{
        display: none;
    }

}