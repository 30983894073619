.newDogForm{
    display: flex;
    flex-direction: column;
    padding: 0 30px;

    .newDogWrapper{
        // display: flex;
        margin-bottom: 25px;

        input{
            padding: 10px;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 8px;
        }

        textarea{
            padding: 10px;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 8px;
            width: 100%;
        }

        select{
            padding: 10px;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 8px;
        }
        label{
            margin-left: 5px;
            margin-right: 20px;
        }

        .newDogLabel{
            font-weight: 600;
            margin-bottom: 10px;
            font-size: 18px;
        }



    }

    .formView{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 30px 10px;
    }
    
    .dogFormDiv{
        display: flex;
        padding: 20px 60px;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        max-height: 90vh;
        overflow-x: visible;
        overflow-y: auto;
        div div{
            margin-right: 20px;
        }
        .labelImg{
            width: 100px;
            max-width: 100px;
            height: auto;
        }
        p{
            font-size: 2.5rem;
            font-weight: 700;
            letter-spacing: 0px;
            color: #6504b5;
            font-family: cabin;
        }
    }

    .poochTextField{
        padding: 10px 0 10px;
        border: 0px;
        border-bottom: 1px solid #6504b5;
        font-size: 2rem;
        line-height: 2rem;
        background: none;
        font-family: cabin;

        &:hover{
            border: 0px;
            border-bottom: 1px solid #6504b5;
        }

        &:focus-visible{
            border: 0px;
            border-bottom: 1px solid #6504b5;
            outline: 0;
        }
    }

    .poochTypeWrapper{
        margin: 30px 0 80px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        input{
            margin-right: 15px;
        }

        .imgLabel{
            padding: 30px;
            margin-right: 40px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        }
    }
    
    .dogInfo, .dogImgWrapper{
        width: 50%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;


        p{
            font-weight: 600;
            margin-bottom: 10px;
            font-size: 18px;
        }

        .previewImages{
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            width: 100%;
            overflow: auto;
            overflow-y: hidden!important;
            padding: 0px;

            li{
                margin-right: 20px;
                list-style: none;
                margin-bottom: 15px;

            }
        }
    }
}
.dogFormSubmitButton{
    margin-left: 30px;
    padding: 10px 20px;
    font-family: changa one;
    color: #fff;
    background: #6504b5;
    border: 0px;
    border-radius: 8px;
}


.view-2 .dogFormDiv{
    padding-bottom: 0;
    &>div{
        margin-bottom: 80px;
    }
    label{
        margin: 20px 30px 0 10px;
        font-size: 2rem;
        line-height: 2rem;
        font-family: cabin;
    }

    select{
        padding: 10px 20px;
        font-size: 1.5rem;
        font-family: cabin;
        line-height: 1.6rem;
    }

    .poochGenderWrapper{
        margin: 30px 0 80px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        input{
            margin-right: 15px;
        }

        label{
            padding: 30px;
            margin-right: 40px;
            margin-top: 0;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        }
    }
}


.view-3 .dogFormDiv{
    .poochTypeWrapper{
        margin: 10px 0 80px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: start;

        input{
            margin-right: 10px;
        }

        label{
            // padding: 30px;
            font-size: 2rem;
            line-height: 2rem;
            font-family: cabin;
            margin-right: 40px;
        }
        
    }
}

.view-4 .dogFormDiv{
    .poochTypeWrapper{
        margin: 10px 0 80px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: start;

        input{
            margin-right: 10px;
        }

        label{
            margin: 20px 30px 0 10px;
            font-size: 2rem;
            line-height: 2rem;
            font-family: cabin;
        }
    }

    .form-description{
        width: 50vw
    }
}

.view-5 .dogFormDiv{
    .poochTypeWrapper{
        margin: 10px 0 80px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: start;

        input{
            margin-right: 10px;
        }

        label{
            margin: 20px 30px 0 10px;
            font-size: 2rem;
            line-height: 2rem;
            font-family: cabin;
        }
    }

    .form-description{
        width: 50vw
    }
}


.view-6 {
    flex-direction: column;
    .dogFormDiv{
        .poochTypeWrapper{
            margin: 10px 0 80px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: start;

            input{
                margin-right: 10px;
            }

            label{
                margin: 20px 30px 0 10px;
                font-size: 2rem;
                line-height: 2rem;
                font-family: cabin;
            }
        }

        .heading-view-6{
         font-size: 1.2rem;
        }
        .dogImgWrapper{
            width: 100%;
        }
    }

    .dogFormSubmitButton{

        padding: 10px 20px;
        font-family: durea;
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}



@media only screen and (max-width: 768px) {
    .newDogForm{
        flex-direction: column;

        .poochTypeWrapper{
    
            .imgLabel{
                padding: 20px;
            }
        }
        
        .formView{
            display: flex;
            justify-content: stretch;
            align-items: center;
            padding: 20px 0px 10px;
        }

        .dogFormDiv{
            flex-direction: column;
            justify-content: start;
            align-items: start;
            padding: 20px 0;
            overflow: visible;
            // height: calc(100vh - 25px);
            max-width: 100%;

            p{
                font-size: 1rem;
                font-weight: 700;
                letter-spacing: 0px;
                color: #6504b5;
                font-family: cabin;
            }

            .labelImg{
                width: 50px !important;
                max-width: 50px;
                height: auto;
            }
        }

        .poochTextField{
            padding: 10px 0 10px;
            border: 0px;
            border-bottom: 1px solid #6504b5;
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
    }
    


    .view-2 .dogFormDiv{
    .poochGenderWrapper{
        label{
            padding: 20px;
        }
    }

        label{
            margin: 20px 30px 0 10px;
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-family: cabin;
        }
    
        select{
            padding: 10px 20px;
            font-size: 1rem;
            font-family: cabin;
            line-height: 1rem;
        }
    
    }

    .view-3 .dogFormDiv{
        .poochTypeWrapper{
            margin: 10px 0 40px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: start;
    
            input{
                margin-right: 10px;
            }
    
            label{
                // padding: 30px;
                font-size: 1.2rem;
                line-height: 1.2rem;
                font-family: cabin;
                margin-right: 40px;
            }
            
        }
    }

    .view-4 .dogFormDiv{
        div{
            width: 100%;
        }
        .poochTypeWrapper{
            margin: 0px 0 80px;
            max-width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: start;
    
            input{
                margin-right: 10px;
                max-width: 60%;
            }
    
            label{
                font-size: 1.2rem;
                line-height: 1.2rem;
            }
        }
    
        .form-description{
            width: 100%;
            font-size: 1.2rem !important;
        }
    }

    .view-5 .dogFormDiv{
        .poochTypeWrapper{
            margin: 0px 0 80px;
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: start;
    
            input{
                margin-right: 10px;
            }
    
            label{
                margin: 20px 30px 0 10px;
                font-size: 1.2rem;
                line-height: 1.2rem;
                font-family: cabin;
            }
        }
    }
    
    
}