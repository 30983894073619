.sellerprofile{
    padding: 15px 30px;
    .profileForm{
        .formRow{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 12px;
            div{
                width: 32%;
                display: flex;
                flex-direction: column;
            }
            input, select{
                padding: 12px;
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }
    }
}