.mobileNav{
    display: none;
}

@media only screen and (max-width: 768px) {
    .mobileNav{
        background: #fff;
        display: flex;
        position: fixed;
        bottom: -1px;
        z-index: 11;
        width: 100%;
        justify-content: space-evenly;

        .DhashNavLink{
            display: flex;
            flex-grow: 1;
            padding:15px 20px;
            align-items: center;
            justify-content: center;
            &.active{
                background: #6504b5;
            }
            &.mobileHomeLink{
                padding: 0px 20px;   

                img{
                    border-radius: 50%;
                    transform: scale(2.2);        
                }
            }
        }

        img{
            width: 25px;
            height: auto;
        }

        
    }
}