.newDogForm {
  display: flex;
  padding: 0 30px;
}
.newDogForm .newDogWrapper {
  margin-bottom: 25px;
}
.newDogForm .newDogWrapper input {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.newDogForm .newDogWrapper textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 100%;
}
.newDogForm .newDogWrapper select {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.newDogForm .newDogWrapper label {
  margin-left: 5px;
  margin-right: 20px;
}
.newDogForm .newDogWrapper .newDogLabel {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
}
.newDogForm .newDogWrapper .dogFormDiv {
  display: flex;
}
.newDogForm .newDogWrapper .dogFormDiv div {
  margin-right: 20px;
}
.newDogForm .dogInfo, .newDogForm .dogImgWrapper {
  width: 50%;
}
.newDogForm .dogInfo p, .newDogForm .dogImgWrapper p {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
}
.newDogForm .dogInfo .previewImages, .newDogForm .dogImgWrapper .previewImages {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
  overflow-y: hidden !important;
  padding: 0px;
}
.newDogForm .dogInfo .previewImages li, .newDogForm .dogImgWrapper .previewImages li {
  margin-right: 20px;
  list-style: none;
  margin-bottom: 15px;
}

.dogFormSubmitButton {
  margin-left: 30px;
  padding: 10px 20px;
  font-family: changa one;
  color: #fff;
  background: #6504b5;
  border: 0px;
  border-radius: 8px;
}/*# sourceMappingURL=style.css.map */