.loginPopupModal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loginPopUp{
        background: #fff;
        padding: 20px;
        border-radius: 12px;

        p{
            font-family: poppins;
        }

        .loginPopUpHeader{
            font-family: durea;
        }

        .loginPopUpPara{
            margin-bottom: 40px;
        }
    }
    .otpWrapper{
        input{
            padding: 10px;
            border: 0;
            border-bottom: 1px solid #6504b5;
            font-family: poppins;
            margin-bottom: 20px;
            outline: 0;
        }

        button{
            font-family: poppins;
            background: #6504b5;
            color: #fff;
            text-transform: uppercase;
            font-size: 0.9rem;
            border: 0;
            padding: 15px 30px;
            border-radius: 8px;
            font-weight: 700;
        }
    }
}