.newDogForm{
    display: flex;
    padding: 0 30px;

    .newDogWrapper{
        // display: flex;
        margin-bottom: 25px;

        input{
            padding: 10px;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 8px;
        }

        textarea{
            padding: 10px;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 8px;
            width: 100%;
        }

        select{
            padding: 10px;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 8px;
        }
        label{
            margin-left: 5px;
            margin-right: 20px;
        }

        .newDogLabel{
            font-weight: 600;
            margin-bottom: 10px;
            font-size: 18px;
        }



        .dogFormDiv{
            display: flex;
            div{
                margin-right: 20px;
            }
        }
    }

    .dogInfo, .dogImgWrapper{
        width: 50%;

        p{
            font-weight: 600;
            margin-bottom: 10px;
            font-size: 18px;
        }

        .previewImages{
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            width: 100%;
            overflow: auto;
            overflow-y: hidden!important;
            padding: 0px;

            li{
                margin-right: 20px;
                list-style: none;
                margin-bottom: 15px;

            }
        }
    }
}
.dogFormSubmitButton{
    margin-left: 30px;
    padding: 10px 20px;
    font-family: changa one;
    color: #fff;
    background: #6504b5;
    border: 0px;
    border-radius: 8px;
}