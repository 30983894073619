.dogForSaleCard{
    width: 24%;
    border-radius: 8px;
    // box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    font-family: poppins;
    padding: 15px;
    margin-bottom: 20px;
    margin-right: 1%;
    box-shadow: 0px 0px 5px 0px #b6b6b6;
    background: #fff;

    .dogPic{
        width: 100%;
    }

    .pupDetail{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .detailWrapper{
        display: flex;
        align-items: center;
        img{
            width: 20px;
            height: auto;
            margin-right: 15px;
        }
    }

    h3{
        margin: 15px 0;
        font-family: poppins;
        font-weight: 700;
        font-size: 1rem;

    }
    p{
        margin-bottom: 5px;
    }
    .actionWrapper{
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
        button{
            padding: 10px 20px;
            // letter-spacing: 0.2px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            font-family: poppins;
            border: 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px;
        }
        .whatsappEnquire{
            background: #64b161;
            color: white;
            margin-right: 20px;

            img{
                height: 20px;
                width: auto;
                margin-right: 10px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .dogForSaleCard{
        width: 100%;
        border-radius: 8px;
        // box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
        font-family: poppins;
        padding: 0px 0px;
        margin-bottom: 20px;
        background: #fff;
        box-shadow: 0px 0px 5px 0px #b6b6b6;

        .dogPic{
            width: 100%;
            border-radius: 8px;
        }

        .pupDetail{
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 5px;
        }

        .detailWrapper{
            display: flex;
            align-items: center;
            img{
                width: 20px;
                height: auto;
                margin-right: 15px;
            }
        }

        h3{
            margin-bottom: 0px;
            font-family: changa one;
            font-size: 0.9rem;
            padding: 10px ;

        }
        p{
            margin-bottom: 5px;
        }
        .actionWrapper{
            margin-top: 0px;
            display: flex;
            justify-content: space-around;
            padding: 10px;
            button{
                padding: 10px 15px;
                // letter-spacing: 0.2px;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 12px;
                font-family: poppins;
                border: 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 8px;
            }
            .whatsappEnquire{
                background: #64b161;
                color: white;
                margin-right: 0px;

                img{
                    height: 20px;
                    width: auto;
                    margin-right: 10px;
                }
            }
        }
    }
}