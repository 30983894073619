.DashNavUserWrapper{
    width: 220px;
    position: sticky;
    top: 0;
    left: 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 10px 0px 0;
    background: #fff;

    // .linkWrapper{
    //     padding: 20px;
    // }

    .DhashNavLogo{
        width: 80px;
        height: auto;
    }

    .DhashNavLink{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        text-decoration: none;
        color:#6504b5;
        font-size: 14px;
        // text-transform: uppercase;
        padding: 0 0 5px 0;
        border-left: 5px solid #fff;


        &.bottom{
            padding: 20px 0;
        }


        &.logout{
            background: #2e0152;
            color: #fff;
        }

        &.logo{
            margin-bottom: 10px;
            &:hover{
                background: #fff;
            }
        }

        &:hover{
            color:#fff;
            background: #2e0152;
            border-left: 5px solid #2e0152;
        }

        &.logo{
            justify-content: center;
        }

        .DashNavIcon{
            width: 30%;
            height: auto;
            transform: scale(1.2);
            margin-bottom: 5px;
        }

        p{
            font-family: poppins !important;
            font-size: 0.9rem !important;
            font-weight: 700 !important;
            letter-spacing: 0px !important;
            margin-bottom: 0;
        }
    }

    .active{
        background: #6504b5;
        border-left: 5px solid #2e0152;
        color: #fff;
        box-shadow: 0px 0px 5px 0px #b6b6b6;
        z-index: 11;

        &:hover{
            background: #2e0152
        }
    }
}

@media only screen and (max-width: 768px) {
    .DashNavUserWrapper{
        display: none;
    }
}
