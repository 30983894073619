.shopWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 52px;
  font-weight: 700;
  font-family: poppins;
  flex-grow: 1;
}

.pupListWrapper.shop {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

@media only screen and (max-width: 768px) {
  .shopWrapper {
    font-size: 32px;
  }
}/*# sourceMappingURL=style.css.map */

.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.product {
  border: 2px solid #ddd;
  padding: 10px;
  text-align: center;
  color: #6504b5;
}

.product img {
  max-width: 100%;
  height: auto;
}

.store {
  max-width: 800px;
  padding: 20px;
}

.out-of-stock {
  opacity: 0.7; /* Reduce opacity for out-of-stock products */
}

.out-of-stock-message {
  color: #ff0000; /* Red text for the out-of-stock message */
  font-weight: bold;
}
