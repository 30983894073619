.billingWrapper{
    margin: 15px 30px;
    background:#fff;
    padding: 30px 20px;

    h3{
        font-family: changa one;
    }

    .currentPlan{
        div.currentPlanWrapper{
            background:#f5f5f5;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
        }
        b{
            margin-right: 10px;
        }
        p{
            margin-bottom: 5px;
            &:nth-child(2){
                margin-bottom: 0px;
            }
        }
    }

    .previousBilling{
        .bill{
            margin-top: 20px;
            padding: 10px 20px;
            background: #f5f5f5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            b{
                margin-right: 10px;
            }
            p{
                margin-bottom: 5px;
                &:nth-child(2){
                    margin-bottom: 0;
                }
            }
            button{
                border: 0;
                background: #fff;
                color: #6504b5;
                padding: 10px;
            }
        }
    }
}