.headerWrapper{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0px 0px 0 20px;
    // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(101,4,181,1) 100%);
    background:  rgba(2,0,36,1);
    // background: #6504b5;

    & > div{
        display: flex;
        align-items: center;

        p{
            color: #fff;
            margin-bottom: 0;
            margin-right: 20px;
            font-family: changa one;
            margin-left: 30px;
            font-weight: 500;
        }

        &.userActions{
            background: #fff;
            // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(101,4,181,1) 100%);

            & .navLink{
                color: #6504b5;
                &:hover{
                    color: #fff;
                }
            // color: #fff;
            }

            & p:nth-child(1){
                color: #6504b5;
                // background: rgba(101,4,181,1);
                // margin-left: 0;
            }
        }
    }

    .headerLogo{
        height: 80px;
        width: auto;
        border-radius: 50%;
        margin: 10px 20px 10px 0;
    }

    .navLink{
        font-family: changa one;
        text-decoration: none;
        font-weight: 500;
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 20px;
        transition: 0.1s all;

        &:hover{
            color: #fff;
            background: #6504b5;
        }

        &.login{
            background: #6504b5;
            color: #fff!important;
        }

        // &.sellerLink{
        //     &:hover{
        //         background: #fff;
        //     }
        // }
    }
}

.mobileHeader{
    display: none!important;
}

@media only screen and (max-width: 768px) {
    .headerWrapper{
        display: none;
    }
    
    .mobileHeader{
        display: block!important;
        font-family: poppins;
        font-size: 14px;
        font-weight: 500;
        position: sticky !important;
        top: 1px !important;

        a{
            text-decoration: none;
            color: #6504b5;
        }

        .headerLogo{
            width: 70px;
        }
    }
}