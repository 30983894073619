.sellerRegisterWrapper{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    section{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        
        button{
            padding: 15px 50px;    
            background: #6504b5;
            border: 0px;
            color: #fff;
            font-family: changa one;
            font-size: 2rem;
            border-radius: 12px;
        }

        .userForm{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            input{
                margin-right: 20px;
            }

            

            input, label{
                margin-bottom: 20px;
            }
        }

        &:nth-child(1){
            p{
                font-weight: 400;
                font-family: poppins;
                font-size: 1.1rem;
                &:nth-child(1){
                    font-weight: 700;
                    font-family: changa one;
                    font-size: 2rem;
                }


            }
        }
    }
}