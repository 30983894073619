.sellerDashboardWrapper{
    padding: 0 30px;
}
.dogsForSaleWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    width: 100%;
}
.sellerHeader{
    position: sticky;
    top: -1px;
    padding: 20px 0;
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    
    .filterInput{
        border: 1px solid rgba(0,0,0,0.3);
        border-radius: 15px;
        padding: 10px 20px;
        font-family: poppins;
        width: 300px;
    }
}
.sellerHeading{
    font-family: poppins;
}

.filterButton{
    padding: 15px 30px;
    border-radius: 15px;
    background: #6504B5;
    color: #fff;
    font-family: poppins;
    font-weight: 600;
    border: 0px;
    letter-spacing: 0.3px;
}


@media only screen and (max-width: 768px) {
    .sellerHeader{
        position: sticky;
        top: -1px;
        padding: 20px 0 5px;
        background: #f5f5f5;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        font-size: 0.8rem;
        
        .filterInput{
            border: 1px solid rgba(0,0,0,0.3);
            border-radius: 15px;
            padding: 10px 20px;
            font-family: poppins;
            margin-right: 5%;
        }

        .filterButton{
            text-wrap: nowrap;
            padding: 10px 20px;
            width: 100%;
        }

    }
}
