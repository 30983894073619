.loginWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .loginBg{
        background: url("../../../Assets/backgroundLogin.jpg");
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        width: 50%;
        height: 100vh;
    }

    .loginActions{
        width: 50%;
        padding: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        font-family: poppins;

        .loginForm{
            width: 100%;
            margin-bottom:20px ;

            &.signup{
                .formWrapper{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                }
                div{
                    width: 50%;
                    padding-right: 20px;

                    input{
                        width: 100%;
                    }
                }
            }

        }

        h1{
            font-family: changa one;
            margin-bottom: 40px;
        }

        div{
            
            margin-bottom: 20px;
        }

        label{
            font-family: changa one;
            margin-bottom: 10px;
            font-size: 20px;
        }
        button{
            font-family: poppins;
            background: #6504b5;
            color: #fff;
            padding: 15px 30px;
            border: 0;
            border-radius: 30px;
            margin-right: 20px;
            display: flex;
            justify-content: center;
            font-family: changa one;
            align-items: center;
            font-size: 1rem;
            &.signup{
                border: 1px solid #6504b5;
                background: #fff;
                color: #6504b5;
            }
            svg{
                margin-left: 10px;

                &.MuiCircularProgress-svg{
                    margin-left: 0;
                }
            }
        }

        input{
            padding: 5px;
            // width: 60%;
            border: 0;
            border-bottom: 1px solid #cfcfcf;
            outline: 0;
            font-family: changa one;
            font-size: 1.2rem;
            margin: 0 0 20px 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .loginWrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: stretch;
    
        .loginBg{
            background: url("../../../Assets/backgroundLogin.jpg");
            background-position: 0 60%;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 25vh;
        }
    
        .loginActions{
            width: 100%;
            padding: 20px;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            font-family: poppins;
    
            .loginForm{
                width: 100%;
                margin-bottom:20px ;
    
                &.signup{
                    .formWrapper{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        justify-content: space-between;
                    }
                    div{
                        width: 100%;
                        padding-right: 0px;
    
                        input{
                            width: 100%;
                        }
                    }
                }
    
            }
    
            h1{
                font-family: changa one;
                margin-bottom: 40px;
            }
    
            div{
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
            }
    
            label{
                font-family: changa one;
                margin-bottom: 10px;
                font-size: 20px;
            }
            button{
                font-family: poppins;
                background: #6504b5;
                color: #fff;
                padding: 10px 30px;
                border: 0;
                border-radius: 30px;
                margin-right: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.8rem;
                &.signup{
                    border: 1px solid #6504b5;
                    background: #fff;
                    color: #6504b5;
                }
            }
    
            input{
                padding: 20px;
                border-radius: 20px;
                width: 100%;
                border: 1px solid #cfcfcf;
            }
        }
    }
}